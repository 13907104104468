import React from "react";

function Loader() {
  return (
    <div className="d-flex justify-content-center align-items-center vw-100 vh-100">
      <div className="loader triangle">
        <svg viewBox="0 0 86 80">
          <polygon points="43 8 79 72 7 72"></polygon>
        </svg>
      </div>
    </div>
  );
}

export default Loader;
