import React, { useState } from "react";
import { Row, Col, Modal } from "react-bootstrap";

import { IoPersonSharp } from "react-icons/io5";
import { BsBookmark, BsBookmarkFill , BsFillTelephoneFill, BsFillEnvelopeAtFill, BsGlobe, BsPinMapFill} from "react-icons/bs";
import myResumeIcon from "../../../assets/images/myResumeIcon.png";
import videoPlay from "../../../assets/images/videoplay.svg";
import parse from "html-react-parser";

const months = {
  1: "January",
  2: "February",
  3: "March",
  4: "April",
  5: "May",
  6: "June",
  7: "July",
  8: "August",
  9: "September",
  10: "October",
  11: "November",
  12: "December",
};
const files = {
  resume: "resume",
  resumeVideo: "video resume",
  reference: "reference review",
};
function CandidateModal({
  showCandidateModal,
  toggleCandidateModal,
  candidateIndex,
  setCandidateIndex,
  candidates,
}) {
  const selectedCandidate = candidates[candidateIndex];
  const [showCandidateDetailsModal, setShowCandidateDetailsModal] =
    useState(false);
  const toggleCandidateDetailsModal = () => {
    setShowCandidateDetailsModal(!showCandidateDetailsModal);
  };
  const [isLoading, setIsLoading] = useState(false);
  const selectPrev = () => {
    setCandidateIndex((prev) => prev - 1);
  };
  const selectNext = () => {
    setCandidateIndex((prev) => prev + 1);
  };
  const uploadResume = () => {
    setIsLoading(true);
    const formData = new FormData();
  };

  const openFile = (name) => () => {
    if (selectedCandidate.candidate[name]?.url) {
      window.open(selectedCandidate.candidate[name].url, "_blank");
    } else {
      alert(`No ${files[name]} uploaded by this candidate`);
    }
  };
  if (selectedCandidate) {
    return (
      <>
        <Modal
          size="xl"
          show={showCandidateModal}
          onHide={toggleCandidateModal}
          centered
        >
          <Modal.Header closeButton />
          <Modal.Body className="custom_job_modal_body">
            <Row className="m-0 p-5 job_modal_container">
              <Col className="d-flex flex-column justify-content-around modal_left_job_details p-3" md={3}>
                <div>
                  <p className="fw-bold transparent_btn fs-20 text-start">
                    Reference
                  </p>
                  <span className="fs-12 text-muted">                    
                      {selectedCandidate.candidate.phone && <p><BsFillTelephoneFill></BsFillTelephoneFill><span className="ms-2">{selectedCandidate.candidate.phone} </span></p>}
                     
                      {selectedCandidate.email && <p><BsFillEnvelopeAtFill></BsFillEnvelopeAtFill><span className="ms-2">{selectedCandidate.email}</span></p>}                      
                      
                      {
                        selectedCandidate.candidate.address && 
                        <p>
                      <BsPinMapFill></BsPinMapFill>
                      <span className="ms-2">
                      {selectedCandidate.candidate &&
                        selectedCandidate.candidate.address &&
                        selectedCandidate.candidate.address.city &&
                        selectedCandidate.candidate.address.city}
                      {selectedCandidate.candidate &&
                      selectedCandidate.candidate.address &&
                      selectedCandidate.candidate.address.state
                        ? ", " + selectedCandidate.candidate.address.state
                        : ""}
                      {selectedCandidate.candidate &&
                      selectedCandidate.candidate.address &&
                      selectedCandidate.candidate.address.country
                        ? ", " + selectedCandidate.candidate.address.country
                        : ""}
                        </span>
                        </p>
                      }
                  </span>
                </div>

                {selectedCandidate.candidate.resumeVideo?.url && (
                  <div className="d-flex flex-column my-4">
                    <span className="fw-bold">Video Resume</span>
                    <video
                      width="100%"
                      className="rounded"
                      src={selectedCandidate.candidate.resumeVideo.url}
                      controls
                    />
                  </div>
                )}

                <div className="d-flex flex-column">
                  <span className="fw-bold text-center">
                    <BsBookmark className="me-2" color="var(--purple-color)" />
                    Save the candidate
                  </span>
                  <button
                    className="purple_btn py-3 px-2 fs-20 opacity-100 mt-4"
                    onClick={toggleCandidateDetailsModal}
                  >
                    Hire Now
                  </button>
                </div>
              </Col>
              <Col className="ms-3 d-flex flex-column justify-content-center align-items-start md={9}">
                <Row className="m-0 p-0 w-100 d-flex align-items-center">
                  <Col className="d-flex justify-content-between align-items-center  w-100">
                    <h1 className="fw-bold text-capitalize transparent_btn fs-32">
                    {selectedCandidate.fullName}
                    </h1>
                    <div>
                      {candidateIndex > 0 && (
                        <button
                          className="reverse_purple_border_btn py-2 px-3 fs-20 opacity-100"
                          onClick={selectPrev}
                        >
                          Prev
                        </button>
                      )}
                      {candidateIndex < candidates.length - 1 && (
                        <button
                          className="reverse_purple_border_btn py-2 px-3 fs-20 opacity-100 mt-4 ms-2"
                          onClick={selectNext}
                        >
                          Next
                        </button>
                      )}
                    </div>
                  </Col>
                </Row>
             
                <Row className="m-0 p-0 mt-3">
                  <Col className="d-flex flex-column justify-content-center align-items-start">
                    <span className="fw-bold">Profile</span>
                    <div className="text-muted mt-3">
                      {parse(
                        selectedCandidate.candidate.additionalDetails
                          .personalBio
                      )}
                    </div>
                  </Col>
                </Row>
                <Row className="m-0 p-0 mt-3">
                  <Col className="d-flex flex-column justify-content-center align-items-start">
                    <p className="fw-bold">Professional Experience</p>
                    {selectedCandidate.candidate.experiences.map(
                      (experience, idx) => (
                        <div key={idx}>
                          <p className="mb-1">
                            {experience.occupation}, {experience.company},{" "}
                            {experience.location}
                          </p>
                          <p className="text-muted">
                            {months[experience.startDate.month]}{" "}
                            {experience.startDate.year} -{" "}
                            {experience.isPresent
                              ? "Present"
                              : `${months[experience.endDate.month]} ${
                                  experience.endDate.year
                                }`}
                          </p>
                          <div className="text-muted">
                            {parse(experience.description || "")}
                          </div>
                        </div>
                      )
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>

        <Modal
          show={showCandidateDetailsModal}
          onHide={toggleCandidateDetailsModal}
          dialogClassName="custom_modal_size"
          centered
        >
          <Modal.Header closeButton />
          <Modal.Body className="custom_job_modal_body p-0">
            <div className="border-bottom p-4">
              <h1 className="fw-bold fs-32">Candidate Details</h1>
            </div>
            <div className="p-4">
              <div
                className="p-4 form-control my-3 d-flex cursor_pointer bg-transparent"
                onClick={openFile("resume")}
              >
                <img
                  src={myResumeIcon}
                  alt="resume"
                  className="me-2"
                  width="30px"
                />
                <span className="ms-2 transparent_btn fs-18">Resume</span>
              </div>{" "}
              <div
                className="p-4 form-control my-3 d-flex cursor_pointer bg-transparent"
                onClick={openFile("resumeVideo")}
              >
                <img
                  src={videoPlay}
                  alt="video"
                  className="me-2"
                  width="30px"
                  height="30px"
                />
                <span className="ms-2 transparent_btn fs-18">Video Resume</span>
              </div>
              <div
                className="p-4 form-control my-3 d-flex cursor_pointer bg-transparent"
                onClick={openFile("reference")}
              >
                <div>
                  <IoPersonSharp color="var(--purple-color)" size={30} />
                  <span className="ms-2 transparent_btn fs-18">
                    References Review
                  </span>
                </div>
              </div>
              <a className="mt-4 d-flex justify-content-between align-items-center">
                <button className="purple_btn py-3 px-5 fs-20 opacity-100 w-100 me-2">
                  Hire
                </button>
                <button className="reverse_purple_border_btn fs-20 py-3 px-5 w-100 ms-2">
                  Next
                </button>
              </a>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
  return <></>;
}

export default CandidateModal;
