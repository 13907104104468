import React, { useContext } from "react";
import { Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import HirenowSmallIcon from "../../assets/images/HirenowSmallIcon.png";
import Logo from "../../assets/images/Logo_Hirenow.png";
import { AiFillTwitterCircle, AiOutlineInstagram } from "react-icons/ai";
import { ImFacebook2 } from "react-icons/im";
import { windowSizeContext } from "../../App";
import "../../assets/styles/Footer.css";

function Footer() {
  const user = useSelector((state) => state.user);
  const windowSize = useContext(windowSizeContext);
  const navigate = useNavigate();
  const handleOnClick = (link) => () => {
    navigate(`${link}`);
  };

  return (
    <div className="bg-white">
      <Row className=" w-95 m-auto  ">
        <Col
          className="footer_body m-0 p-0 p-5 d-flex flex-column justify-content-center"
          md={windowSize < 1070 ? 12 : 3}
          xs={12}
        >
          {/* <ul className="list-unstyled">
            <li>
              <a href="search_jobs">Search Jobs</a>
            </li>
            <li>
              <a href="upload_resume">Upload Resume</a>
            </li>
            <li>
              <a href="help_center">Help Center</a>
            </li>
          </ul> */}
          <img
            src={Logo}
            width="180px"
            alt="Logo HireNow"
            className="nav_logo ms-5 cursor_pointer"
            onClick={handleOnClick("/")}
          />

          <div className="mt-4 ms-5 footer_social_container">
            <AiFillTwitterCircle
              color="#999999"
              size={30}
              className="cursor_pointer footer_social_icon"
            />
            <AiOutlineInstagram
              color="#999999"
              size={30}
              className="mx-2 cursor_pointer footer_social_icon"
            />
            <ImFacebook2
              color="#999999"
              size={25}
              className="cursor_pointer footer_social_icon"
            />
          </div>
        </Col>
        <Col
          className={`footer_body m-0 p-0 p-5 d-flex ${
            windowSize < 1070 && "flex-column"
          } align-items-center justify-content-around`}
          md={windowSize < 1070 ? 12 : 6}
          xs={12}
        >
          <span
            onClick={handleOnClick("PrivacyPolicy")}
            className="cursor_pointer text-muted fs-18"
          >
            Privacy Policy
          </span>
          <span
            onClick={handleOnClick("TermsOfUse")}
            className="cursor_pointer text-muted fs-18"
          >
            Terms of Use
          </span>
          <span
            onClick={handleOnClick("CookiePolicy")}
            className="cursor_pointer text-muted fs-18"
          >
            Cookie Policy
          </span>

          {user._id ? (
            <span
              onClick={handleOnClick("Faq")}
              className="cursor_pointer text-muted fs-18"
            >
              Help Center
            </span>
          ) : (
            <span
              onClick={handleOnClick("FaqHome")}
              className="cursor_pointer text-muted fs-18"
            >
              Help Center
            </span>
          )}

          {/* <ul className="list-unstyled">
            <li>
              <a href="post_jobs">Post a job</a>
            </li>
            <li>
              <a href="search_candidates">Search Candidate</a>
            </li>
            <li>
              <a href="job_posting_policy">Job posting policy</a>
            </li>
          </ul> */}
        </Col>
        {/* <Col className="footer_body d-flex justify-content-center m-0 p-0">
          <ul className="list-unstyled">
            <li>
              <a href="privacy_policy">Privacy Policy</a>
            </li>
            <li>
              <a href="terms_of_use">Terms of Use</a>
            </li>
            <li>
              <a href="cookie_policy">Cookie Policy</a>
            </li>
          </ul>
        </Col> */}
        <Col
          className="footer_body d-flex align-items-center justify-content-center m-0 p-0 p-5"
          md={windowSize < 1070 ? 12 : 3}
          xs={12}
        >
          {/* <ul className="list-unstyled"> */}
          {/* <li>
              <img
                src={HirenowSmallIcon}
                alt="facebook"
              />
            </li> */}
          <span className="text-muted fs-20">
            2150 Town Square
            <br />
            Place, Suite 200
            <br />
            Sugar Land, Texas
            <br />
            77479
          </span>
          {/* <li>
              <img
                src="https://img.icons8.com/color/48/000000/twitter.png"
                alt="twitter"
              />
              <img
                src="https://img.icons8.com/color/48/000000/instagram-new.png"
                alt="instagram"
              />
              <img
                src="https://img.icons8.com/color/48/000000/facebook-new.png"
                alt="facebook"
              />
            </li> */}
          {/* </ul> */}
        </Col>
      </Row>
    </div>
  );
}

export default Footer;
