import React, { useContext } from "react";
import Logo from "../../assets/images/Logo_Hirenow.png";
import { Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { RiNotification4Fill } from "react-icons/ri";
import { GiRoundStar } from "react-icons/gi";
import { FaUser } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../../slices/userSlice";
import { windowSizeContext } from "../../App";
import "../../assets/styles/Nav.css";

function NavbarEmployer({ toggleLogin }) {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleOnClick = (link) => () => {
    navigate(`${link}`);
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    dispatch(logout());
    alert("You are logged out");
    navigate("/");
    window.location.reload();
  };

  return (
    <div className="bg-white">
      <Row className="w-95 nav_container m-auto d-flex py-4 justify-content-between align-items-center">
        <Col className="d-flex flex-columnm-0 ms-5 ps-5" md={3} xs={6}>
          <div className="d-block">
            <img
              src={Logo}
              width="130px"
              alt="Logo HireNow"
              className="nav_logo cursor_pointer"
              onClick={handleOnClick("/")}
            />

            <p
              className="mt-2 mb-0 fs-12 text-left primary_color_text"
              style={{ letterSpacing: "2px" }}
            >
              EMPLOYERS
            </p>
          </div>
        </Col>
        {/* <Col className="d-flex ">
          <button
            className="grey_color_text fw-700 bg-transparent border-0 me-5"
            onClick={handleOnClick("/postingJob")}
          >
            Post a Job
          </button>
          <button
            className="grey_color_text fw-700 bg-transparent border-0 me-5"
            onClick={handleOnClick("/jobs")}
          >
            Jobs
          </button>
          <button
            className="grey_color_text fw-700 bg-transparent border-0 me-5"
            onClick={handleOnClick("/candidates")}
          >
            Candidates
          </button>
          <button
            className="grey_color_text fw-700 bg-transparent border-0"
            onClick={handleOnClick("/resumes")}
          >
            Search Resume
          </button>
        </Col> */}
        <Col className=" d-flex" md={3} xs={6}>
          {user._id ? (
            <div className="">
              {/* <button
                className="fw-700 bg-transparent border-0 me-5"
                onClick={handleOnClick("/jobs")}
              >
                Search Jobs
              </button> */}
              <div className="d-flex">
                <button
                  onClick={handleOnClick("postingJob")}
                  className="text-black fw-bold profile_btn m-0 fs-16 mt-1 me-4"
                >
                  Post Jobs
                </button>
                <button
                  onClick={handleOnClick("/notifications")}
                  className="m-0 mx-3 profile_btn"
                >
                  <RiNotification4Fill color="var(--purple-color)" size={25} />
                </button>
                <button className="m-0 mx-3 profile_btn">
                  <GiRoundStar
                    color="var(--purple-color)"
                    size={25}
                    onClick={handleOnClick("ShortlistedCandidates")}
                  />
                </button>
                <div className="profile_dropdown">
                  <button className="m-0 mx-3 profile_btn">
                    <FaUser color="var(--purple-color)" size={25} />
                  </button>
                  <div className="profile_dropdown_content">
                    <button
                      onClick={handleOnClick("Profile")}
                      className="profile_dropdown_item profile_btn m-0"
                    >
                      My Profile
                    </button>

                    <button
                      onClick={handleOnClick("postedJobs")}
                      className="profile_dropdown_item profile_btn m-0"
                    >
                      Posted Jobs
                    </button>
                    <button
                      onClick={handleOnClick("accountSettings")}
                      className="profile_dropdown_item profile_btn m-0"
                    >
                      Account Settings
                    </button>
                    <button
                      onClick={handleOnClick("Faq")}
                      className="profile_dropdown_item profile_btn m-0"
                    >
                      FAQ's
                    </button>
                    <button
                      onClick={handleLogout}
                      className="profile_dropdown_item profile_btn m-0"
                    >
                      Logout
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <button className="nav_link_btn m-0" onClick={toggleLogin}>
              Login | Signup
            </button>
          )}
        </Col>
      </Row>
    </div>
  );
}

export default NavbarEmployer;
