import React, { useState } from "react";
import { Modal, Row, Col } from "react-bootstrap";
import { AiFillCheckCircle } from "react-icons/ai";
import { FcGoogle } from "react-icons/fc";
import "../../assets/styles/Users.css";
import authServices from "../../services/AuthService";

function ResendEmailVerification({
  setChangeLogin,
  toggleSignUp,
  toggleLogin,
}) {
  const [email, setEmail] = useState("");
  const [isLinkSent, setIsLinkSent] = useState(false);

  const onSubmit = () => {
    if (!email || !email?.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
      return alert("Please enter a valid email");
    }
    authServices
      .resendEmailVerification(email)
      .then((res) => {
        if (res.data.error) {
          return alert(res.data.error);
        }

        setIsLinkSent(true);
      })
      .catch((error) => {
        alert(error);
      });
  };

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>
          <h2 className=" text-center fw-600 text-purple ">
            Verify your email address
          </h2>
        </Modal.Title>
      </Modal.Header>
      {/* <p className="mt-5 text-center text-muted">Enter Email Address</p> */}

      {isLinkSent ? (
        <Row className="mt-2">
          <h5 className="text-center">
            <AiFillCheckCircle color="#0dc16e" /> A verification link has been
            sent to your email account
          </h5>
          <br className="login_line my-3" />
          <p className="text-center">
            Please click on the link that has just been sent to your email
            account to verify your email and continue the registration process.
          </p>
        </Row>
      ) : (
        <Row className="mt-2">
          <p className="text-muted text-center">
            Your email is not verified yet.
          </p>
          <p className="text-muted text-center">
            We will send you a verification link to your email
          </p>
          <br className="login_line my-3" />

          <form className="d-flex flex-column align-items-center mb-5">
            <input
              type="email"
              className="custom_input w-95 d-block"
              placeholder="Enter Email Address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  onSubmit();
                }
              }}
            />
            <button
              type="button"
              className="purple_btn w-25 px-3 py-2 mt-2"
              onClick={onSubmit}
            >
              Send link
            </button>
          </form>
        </Row>
      )}
    </>
  );
}

export default ResendEmailVerification;
