import axios from "axios";

const API_URL = "https://hirenow-be.azurewebsites.net/"
  //process.env.REACT_APP_NODE_ENV === ""
    //? process.env.REACT_APP_DEV_API_URL
    //: process.env.REACT_APP_NODE_ENV === "test"
    //? process.env.REACT_APP_TEST_API_URL
    //: process.env.REACT_APP_PROD_API_URL;

const authServices = {
  register: async (data) => {
    return await axios.post(API_URL + "/api/users", data);
  },

  checkLogin: async (data) => {
    return await axios.get(API_URL + "/api/users/jwt/" + data);
  },

  login: async (data) => {
    return await axios.post(API_URL + "/api/users/login", data);
  },

  verifyEmail: async (data) => {
    return await axios.put(API_URL + "/api/users/confirmation/verify", data);
  },

  resendEmailVerification: async (email) => {
    return await axios.get(API_URL + "/api/users/confirmation", {
      params: { email },
    });
  },

  sendForgotPasswordLink: async (email) => {
    console.log(email);
    return await axios.put(API_URL + "/api/users/password", { email });
  },

  resetPassword: async (data) => {
    return await axios.put(API_URL + "/api/users/password", data);
  },

  updateProfile: async (id, data) => {
    return await axios.put(API_URL + `/api/users/${id}`, data, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
  },

  getProfile: async (id) => {
    return await axios.get(API_URL + `/api/users/${id}`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
  },

  createJob: async (data) => {
    return await axios.post(API_URL + "/api/jobs", data, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
  },

  editJob: async (id, data) => {
    return await axios.put(API_URL + `/api/jobs/${id}`, data, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
  },

  applyJob: async (id, data) => {
    return await axios.put(API_URL + `/api/jobs/${id}/application`, data, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
  },

  getJob: async (id) => {
    return await axios.get(API_URL + `/api/jobs/${id}`);
  },

  getJobs: async (params) => {
    return await axios.get(API_URL + "/api/jobs", {
      params,
    });
  },

  getCandidates: async (params) => {
    return await axios.get(API_URL + "/api/users", { params });
  },

  uploadDocument: async (data) => {
    return axios.post(API_URL + "/api/aws/documents", data, {
      headers: {
        // Authorization: "Bearer " + localStorage.getItem("token"),
        "content-type": "multipart/form-data",
      },
    });
  },
  uploadImage: async (data) => {
    return axios.post(API_URL + "/api/aws/images", data, {
      headers: {
        // Authorization: "Bearer " + localStorage.getItem("token"),
        "content-type": "multipart/form-data",
      },
    });
  },
  saveJob: async (id, jobId) => {
    return await axios.put(
      API_URL + `/api/users/${id}/jobs/${jobId}/save`,
      {},
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    );
  },

  getSavedJobs: async (id) => {
    return await axios.get(API_URL + `/api/users/${id}/jobs/saved`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
  },

  unSaveJob: async (id, jobId) => {
    return await axios.put(
      API_URL + `/api/users/${id}/jobs/${jobId}/unSave`,
      {},
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    );
  },

  getAppliedJobs: async (id) => {
    return await axios.get(API_URL + `/api/users/${id}/jobs/applied`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
  },

  getPostedJobs: async (id) => {
    return await axios.get(API_URL + `/api/users/${id}/jobs/posted`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
  },

  getJobCandidates: async (id, status) => {
    return await axios.get(
      API_URL + `/api/users/${id}/candidates/status/${status}`,
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    );
  },

  updateJobStatus: async (jobId, status) => {
    return await axios.put(
      API_URL + `/api/users/jobs/${jobId}/status/${status}/update`,
      {},
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    );
  },

  deleteJob: async (id) => {
    return await axios.delete(
      API_URL + `/api/jobs/${id}`, 
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    );
  },

  getAppliedCandidates: async (id) => {
    return await axios.get(API_URL + `/api/jobs/${id}/users`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
  },

  updateCandidateStatus: async (id, userId, status) => {
    return await axios.put(
      API_URL + `/api/jobs/${id}/users/${userId}/status/${status}/update`,
      {},
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    );
  },
  getNotifications: async (id) => {
    return await axios.get(API_URL + `/api/users/${id}/notifications`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
  },
};
export default authServices;
