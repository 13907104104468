import "bootstrap/dist/css/bootstrap.min.css";
import React, { useState, lazy, Suspense, useEffect } from "react";
import Home from "./pages/home/Home";
import EmailVerification from "./pages/users/EmailVerification";
import ResendEmailVerification from "./pages/users/ResendEmailVerification";
import Navbar from "./components/common/Navbar";
import NavbarEmployer from "./components/common/NavbarEmployer";
import Footer from "./components/common/Footer";
import { Modal } from "react-bootstrap";
import checked from "./assets/images/checked.png";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { createContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "./Loader";
import authServices from "./services/AuthService";
import { login } from "./slices/userSlice";
import "./App.css";

export const windowSizeContext = createContext(null);

const Login = lazy(() => import("./pages/users/Login"));
const SignIn = lazy(() => import("./pages/users/SignIn"));
const SignUp = lazy(() => import("./pages/users/SignUp"));
const ForgotPassword = lazy(() => import("./pages/users/ForgotPassword"));
const ResetPassword = lazy(() => import("./pages/users/ResetPassword"));
const Profile = lazy(() => import("./pages/candidate/Profile"));
const MyJobs = lazy(() => import("./pages/candidate/MyJobs"));
const Faq = lazy(() => import("./pages/Faq"));
const FaqHome = lazy(() => import("./pages/FaqHome"));
const PostedJobs = lazy(() => import("./pages/employer/PostedJobs"));
const AppliedCandidates = lazy(() =>
  import("./pages/employer/AppliedCandidates")
);
const ShortlistedCandidates = lazy(() =>
  import("./pages/employer/ShortlistedCandidates")
);

const AccountSettings = lazy(() =>
  import("./pages/accountSetting/AccountSettings")
);
const EmployerProfile = lazy(() => import("./pages/employer/profile/Profile"));
const SearchJobsPage = lazy(() => import("./pages/employer/jobs/JobsPage"));

const PrivacyPolicy = lazy(() => import("./pages/PrivacyPolicy"));
const TermsOfUse = lazy(() => import("./pages/TermsOfUse"));
const CookiePolicy = lazy(() => import("./pages/CookiePolicy"));

const NotificationsPage = lazy(() =>
  import("./components/common/NotificationsPage")
);
const PostJobPage = lazy(() =>
  import("./pages/employer/jobs/jobPosting/PostJobPage")
);

function App() {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [isLogin, setIsLogin] = useState(false);
  const [signup, setSignup] = useState(false);
  const [forgotPassword, setForgotPassword] = useState(false);
  const [resendEmailVerification, setResendEmailVerification] = useState(false);
  const toggleForgotPassword = () => setForgotPassword(!forgotPassword);
  const toggleSignup = () => setSignup(!signup);
  const toggleLogin = () => {
    setIsLogin(!isLogin);
  };
  const toggleResendEmailVerification = () => {
    setResendEmailVerification(!resendEmailVerification);
  };
  const [changeLogin, setChangeLogin] = useState(false);
  const [email, setEmail] = useState("");
  const [congratsModal, setCongratsModal] = useState(false);
  const toggleCongratsModal = () => setCongratsModal(!congratsModal);
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const handleWindowResize = () => {
    setWindowSize(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [handleWindowResize]);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      authServices
        .checkLogin(token)
        .then((res) => {
          if (res.data.message === "User Logged In") {
            console.log(res.data.user);
            dispatch(login(res.data.user));
            return;
          }
          localStorage.removeItem("token");
          setChangeLogin(false);
        })
        .catch((err) => {
          localStorage.removeItem("token");
          setChangeLogin(false);
        });
    }
  }, []);

  return (
    <Suspense fallback={<Loader />}>
      <div className="App">
        <windowSizeContext.Provider value={windowSize}>
          {!user._id || user.type === "candidate" ? (
            <Navbar toggleLogin={toggleLogin} />
          ) : (
            <NavbarEmployer />
          )}

          <Routes>
            <Route
              path="/confirm_email"
              exact
              element={
                <EmailVerification
                  toggleLogin={toggleLogin}
                  toggleResendEmailVerification={toggleResendEmailVerification}
                />
              }
            />
            <Route
              path="/reset_password"
              element={<ResetPassword toggleLogin={toggleLogin} />}
            />
            <Route
              path="/jobs"
              exact
              element={<SearchJobsPage toggleLogin={toggleLogin} />}
            />
            {user && (
              <>
                {user.type === "candidate" ? (
                  <Route path="/Profile" exact element={<Profile />} />
                ) : (
                  <Route path="/Profile" exact element={<EmployerProfile />} />
                )}

                {user.type === "candidate" ? (
                  <Route path="/MyJobs" exact element={<MyJobs />} />
                ) : (
                  <Route path="/postedJobs" exact element={<PostedJobs />} />
                )}

                <Route
                  path="/accountSettings"
                  exact
                  element={<AccountSettings />}
                />

                <Route
                  path="/Notifications"
                  exact
                  element={<NotificationsPage />}
                />
                <Route path="/postingJob" exact element={<PostJobPage />} />
                <Route path="/jobs/:id" element={<PostJobPage />} />
                <Route path="/Candidates" element={<AppliedCandidates />} />
                <Route
                  path="/ShortlistedCandidates"
                  element={<ShortlistedCandidates />}
                />
              </>
            )}
            <Route
              path="/"
              exact
              element={<Home toggleLogin={toggleLogin} />}
            />

            <Route path="/Faq" exact element={<Faq />} />
            <Route path="/PrivacyPolicy" exact element={<PrivacyPolicy />} />
            <Route path="/TermsOfUse" exact element={<TermsOfUse />} />
            <Route path="/CookiePolicy" exact element={<CookiePolicy />} />
            <Route path="/FaqHome" exact element={<FaqHome />} />
          </Routes>
          <Footer />
        </windowSizeContext.Provider>

        {/* --------- login modal ---------- */}

        <Modal
          show={isLogin}
          onHide={toggleLogin}
          dialogClassName="custom_modal_size"
          centered
        >
          <Modal.Body>
            {changeLogin ? (
              <Login
                toggleSignup={toggleSignup}
                toggleLogin={toggleLogin}
                email={email}
                setEmail={setEmail}
                toggleForgotPassword={toggleForgotPassword}
                toggleResendEmailVerification={toggleResendEmailVerification}
              />
            ) : (
              <SignIn
                setEmail={setEmail}
                setChangeLogin={setChangeLogin}
                toggleSignUp={toggleSignup}
                toggleLogin={toggleLogin}
              />
            )}
          </Modal.Body>
        </Modal>

        {/* ------- Sign up modal --------- */}
        <Modal
          size="md"
          show={signup}
          onHide={toggleSignup}
          dialogClassName="custom_modal_size"
          centered
        >
          <Modal.Body>
            <SignUp
              toggleLogin={toggleLogin}
              toggleSignup={toggleSignup}
              toggleCongratsModal={toggleCongratsModal}
            />
          </Modal.Body>
        </Modal>

        {/* ------- Re-send email verification modal --------- */}
        <Modal
          size="md"
          show={resendEmailVerification}
          onHide={toggleResendEmailVerification}
          dialogClassName="custom_modal_size"
          centered
        >
          <Modal.Body>
            <ResendEmailVerification
              toggleResendEmailVerification={toggleResendEmailVerification}
              // toggleSignup={toggleSignup}
              // toggleCongratsModal={toggleCongratsModal}
            />
          </Modal.Body>
        </Modal>

        {/* ------- Forgot password modal --------- */}
        <Modal show={forgotPassword} onHide={toggleForgotPassword} centered>
          <Modal.Body>
            <ForgotPassword toggleLogin={toggleLogin} />
          </Modal.Body>
        </Modal>

        {/* ------- Congrats Modal --------- */}
        <Modal show={congratsModal} onHide={toggleCongratsModal} centered>
          <Modal.Header closeButton />
          <Modal.Body className="p-4 py-5">
            <div className="d-flex justify-content-center align-items-center flex-column">
              <img src={checked} alt="congrats" />
              <h3 className="text-center mt-3 fw-bold text-black">Congrats!</h3>
              <p className="text-center mt-3 text-muted">
                Your account has been created. Get notified about new openings
                right away and get hired faster! Please check your email for a
                verification link.
              </p>
              <button
                className="orange_btn mt-4"
                onClick={() => toggleCongratsModal()}
              >
                Let's Go
              </button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </Suspense>
  );
}

export default App;
