import React, { useEffect, useState } from "react";
import { Row, Col, Modal } from "react-bootstrap";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Picky } from "react-picky";
import "react-picky/dist/picky.css";
import authServices from "../../services/AuthService";
import parse from "html-react-parser";

import JobDetailModal from "./modals/JobDetailModal";
import CandidateModal from "./modals/CandidateModal";
import industryOptions from "../../assets/js/industryOptions";
import experienceOptions from "../../assets/js/experienceOptions";

import PlacesAutocomplete, {
  geocodeByAddress,
} from "react-places-autocomplete";
import { useSelector } from "react-redux";
import "../../assets/styles/Home.css";
import sadFaceIcon from "../../assets/images/sadFace.svg";

function Home({ toggleLogin }) {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const user = useSelector((state) => state.user);

  //FOR SEARCH JOBS
  const [showJobModal, setShowJobModal] = useState(false);
  const toggleJobModal = () => setShowJobModal(!showJobModal);
  const [showApplyModal, setShowApplyModal] = useState(false);
  const toggleApplyModal = () => setShowApplyModal(!showApplyModal);

  const [showJobs, setShowJobs] = useState(true);
  const [showCandidates, setShowCandidates] = useState(false);
  const [isNoResult, setIsNoResult] = useState(false);

  const [jobIndex, setJobIndex] = useState();
  // const [industry, setIndustry] = useState([]);
  // const [experience, setExperience] = useState([]);
  // const [address, setAddress] = useState("");
  const [jobFilter, setJobFilter] = useState({
    industry: [],
    title: "",
    experience: [],
    address: "",
  });

  //FOR SEARCH CANDIDATES

  const [showCandidateModal, setShowCandidateModal] = useState(false);
  const toggleCandidateModal = () => setShowCandidateModal(!showCandidateModal);

  const [candidatePreferredIndustry, setCandidatePreferredIndustry] = useState(
    []
  );
  const [candidateExperience, setCandidateExperience] = useState([]);
  const [candidateAddress, setCandidateAddress] = useState("");
  const [candidateFilter, setCandidateFilter] = useState({
    preferredIndustries: [],
    title: "",
    experience: [],
    address: "",
  });

  const [candidates, setCandidates] = useState([]);
  const [candidateIndex, setCandidateIndex] = useState();
  const [jobs, setJobs] = useState([]);

  const searchJobs = () => {
    // const params = Object.fromEntries(searchParams.entries());
    // if (params.industry) {
    //   if (params.industry.includes(",")) {
    //     params.industry = params.industry.split(",");
    //     setIndustry(() => {
    //       return industryOptions.filter((item) =>
    //         params.industry.includes(item.value)
    //       );
    //     });
    //   } else {
    //     setIndustry(() => {
    //       return industryOptions.filter(
    //         (item) => item.value === params.industry
    //       );
    //     });
    //   }
    // }
    // if (params.min_years_required && params.max_years_required) {
    //   setExperience(
    //     `${params.min_years_required} - ${params.max_years_required} years`
    //   );
    // }
    // if (
    //   !params.industry?.length &&
    //   !params.title &&
    //   !params.min_years_required &&
    //   !params.max_years_required &&
    //   !address
    // ) {
    //   return alert(
    //     "Please make a suitable selection to get the search results."
    //   );
    // }
    let query = { ...jobFilter };
    if (
      query.industry.length === 0 &&
      query.title === "" &&
      !query.experience.value &&
      !query.address
    ) {
      return alert(
        "Please make a suitable selection to get the search results."
      );
    }
    if (query.industry.length > 0) {
      query.industry = query.industry.map((item) => item.value);
    }
    if (query.experience.value) {
      query.min_years_required = query.experience.value.split("-")[0];
      query.max_years_required = query.experience.value.split("-")[1];
    }
    delete query.experience;
    delete query.address;

    // authServices.getJobs(params).then((res) => {
    //   if (res.data.error) {
    //     return alert(res.data.error);
    //   }

    //   setJobs(() => res.data);
    //   if (res.data.length === 0) {
    //     setIsNoResult(true);
    //   } else {
    //     setIsNoResult(false);
    //   }
    // });

    authServices.getJobs(query).then((res) => {
      if (res.data.error) {
        return alert(res.data.error);
      }

      setJobs(() => res.data);
      if (res.data.length === 0) {
        setIsNoResult(true);
      } else {
        setIsNoResult(false);
      }
    });
    setShowJobs(true);
    setShowCandidates(false);
  };

  const searchCandidates = (e) => {
    // let params = Object.fromEntries(searchParams.entries());
    // for (const [key, value] of Object.entries(params)) {
    //   if (key.includes("candidate")) {
    //     params[key.substring(10)] = value;
    //   }
    //   delete params[key];
    // }

    // params["type"] = "candidate";
    let query = { ...candidateFilter };
    if (
      query.preferredIndustries.length === 0 &&
      query.title === "" &&
      !query.experience.value &&
      !query.address
    ) {
      return alert(
        "Please make a suitable selection to get the search results."
      );
    }

    if (query.preferredIndustries.length > 0) {
      query.preferredIndustries = query.preferredIndustries.map(
        (item) => item.value
      );
    }

    if (query.experience.value) {
      query.min_years_required = query.experience.value.split("-")[0];
      query.max_years_required = query.experience.value.split("-")[1];
    }
    delete query.experience;
    delete query.address;
    query.type = "candidate";

    authServices
      .getCandidates(query)
      .then((res) => {
        if (res.data.error) {
          return alert(res.data.error);
        }
        setCandidates(() =>
          res.data.filter((item) => item.candidate.experiences[0])
        );
        if (res.data.length === 0) {
          setIsNoResult(true);
        } else {
          setIsNoResult(false);
        }
      })
      .catch((error) => {
        alert(error.message);
      });

    setShowJobs(false);
    setShowCandidates(true);
  };

  const selectJob = (index) => () => {
    if (user) {
      setJobIndex(index);
      toggleJobModal();
    } else {
      toggleLogin();
    }
  };

  const selectCandidate = (index) => () => {
    if (user.type === "employer") {
      setCandidateIndex(index);
      toggleCandidateModal();
    } else {
      alert("Only recruiter can see candidates' information");
      toggleLogin();
    }
  };

  //google places auto complete
  const handleChangeAddress = (address) => {
    // setAddress(address);
    setJobFilter((prev) => {
      return { ...prev, address };
    });
  };

  const handleSelectAddress = (address) => {
    // geocodeByAddress(address).then((results) => {
    //   setAddress(results[0].formatted_address);
    //   let city = results[0].address_components.filter((item) => {
    //     return item.types.includes(
    //       "locality" || "sublocality" || "neighborhood"
    //     );
    //   })[0];
    //   let state = results[0].address_components.filter((item) => {
    //     return item.types[0] === "administrative_area_level_1";
    //   })[0];
    //   let country = results[0].address_components.filter((item) => {
    //     return item.types[0] === "country";
    //   })[0];

    //   searchParams.set("city", city?.long_name);
    //   searchParams.set("state", state?.long_name);
    //   searchParams.set("country", country?.long_name);
    //   setSearchParams(searchParams);
    // });

    geocodeByAddress(address).then((results) => {
      let city = results[0].address_components.filter((item) => {
        return item.types.includes(
          "locality" || "sublocality" || "neighborhood"
        );
      })[0].long_name;
      let state = results[0].address_components.filter((item) => {
        return item.types[0] === "administrative_area_level_1";
      })[0].long_name;
      let country = results[0].address_components.filter((item) => {
        return item.types[0] === "country";
      })[0].long_name;
      setJobFilter((prev) => {
        return {
          ...prev,
          address: results[0].formatted_address,
          city,
          state,
          country,
        };
      });
    });
  };

  const handleOnChange = (e) => {
    // searchParams.set(e.target.name, e.target.value);
    // setSearchParams(searchParams);
    setJobFilter((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  const handleSelectIndustry = (options) => {
    // setIndustry(options);
    // searchParams.set(
    //   "industry",
    //   options.map((option) => option.value)
    // );
    // setSearchParams(searchParams);
    setJobFilter((prev) => {
      return { ...prev, industry: options };
    });
  };

  const handleSelectExperience = (option) => {
    // setExperience(option);
    // searchParams.set("min_years_required", option.value.split("-")[0]);
    // searchParams.set("max_years_required", option.value.split("-")[1]);
    // setSearchParams(searchParams);
    setJobFilter((prev) => {
      return { ...prev, experience: option };
    });
  };

  const handleSelectCandidatePreferredIndustry = (options) => {
    // setCandidatePreferredIndustry(options);
    // searchParams.set(
    //   "candidate_preferredIndustries",
    //   options.map((option) => option.value)
    // );
    // setSearchParams(searchParams);
    setCandidateFilter((prev) => {
      return { ...prev, preferredIndustries: options };
    });
  };

  const handleOnChangeCandidate = (e) => {
    // searchParams.set(e.target.name, e.target.value);
    // setSearchParams(searchParams);
    setCandidateFilter((prev) => {
      return { ...prev, title: e.target.value };
    });
  };

  const handleSelectCandidateExperience = (option) => {
    // setCandidateExperience(option);
    // searchParams.set(
    //   "candidate_min_years_required",
    //   option.value.split("-")[0]
    // );
    // searchParams.set(
    //   "candidate_max_years_required",
    //   option.value.split("-")[1]
    // );
    // setSearchParams(searchParams);
    setCandidateFilter((prev) => {
      return { ...prev, experience: option };
    });
  };

  const handleChangeCandidateAddress = (address) => {
    // setCandidateAddress(address);
    setCandidateFilter((prev) => {
      return { ...prev, address };
    });
  };

  const handleSelectCandidateAddress = (address) => {
    // geocodeByAddress(address).then((results) => {
    //   setCandidateAddress(results[0].formatted_address);
    //   let city = results[0].address_components.filter((item) => {
    //     return item.types.includes(
    //       "locality" || "sublocality" || "neighborhood"
    //     );
    //   })[0];
    //   let state = results[0].address_components.filter((item) => {
    //     return item.types[0] === "administrative_area_level_1";
    //   })[0];
    //   let country = results[0].address_components.filter((item) => {
    //     return item.types[0] === "country";
    //   })[0];

    //   searchParams.set("candidate_city", city?.long_name);
    //   searchParams.set("candidate_state", state?.long_name);
    //   searchParams.set("candidate_country", country?.long_name);
    //   setSearchParams(searchParams);
    // });
    geocodeByAddress(address).then((results) => {
      let city = results[0].address_components.filter((item) => {
        return item.types.includes(
          "locality" || "sublocality" || "neighborhood"
        );
      })[0].long_name;
      let state = results[0].address_components.filter((item) => {
        return item.types[0] === "administrative_area_level_1";
      })[0].long_name;
      let country = results[0].address_components.filter((item) => {
        return item.types[0] === "country";
      })[0].long_name;
      setCandidateFilter((prev) => {
        return {
          ...prev,
          address: results[0].formatted_address,
          city,
          state,
          country,
        };
      });
    });
  };

  // useEffect(() => {
  //   const hasQueryParams = window.location.href.indexOf("?") !== -1;
  //   if (hasQueryParams) {
  //     searchJobs();
  //   }
  // }, []);

  return (
    <div>
      <JobDetailModal
        showApplyModal={showApplyModal}
        showJobModal={showJobModal}
        toggleApplyModal={toggleApplyModal}
        toggleJobModal={toggleJobModal}
        jobs={jobs}
        jobIndex={jobIndex}
        setJobIndex={setJobIndex}
        toggleLogin={toggleLogin}
        showItems={true}
      />
      <CandidateModal
        showCandidateModal={showCandidateModal}
        toggleCandidateModal={toggleCandidateModal}
        candidateIndex={candidateIndex}
        setCandidateIndex={setCandidateIndex}
        candidates={candidates}
      />
      <Row className="p-0 m-0 px-5">
        <Col className="p-0 m-5 px-4 mt-5 d-flex justify-content-between align-items-start">
          <div className="candidate_search_container p-5">
            <h1 className="text-left fw-bold m-0">Candidate</h1>
            <Row>
              <Col>
                <Picky
                  options={industryOptions}
                  labelKey="label"
                  valueKey="value"
                  multiple={true}
                  includeFilter
                  includeSelectAll
                  value={jobFilter.industry}
                  onChange={handleSelectIndustry}
                  className="form-select-picky bg-transparent my-3"
                  numberDisplayed={1}
                  placeholder="Select Industry"
                />
              </Col>
              <Col>
                <input
                  className="form-control bg-transparent my-3 sixteen_px_text"
                  name="title"
                  // value={searchParams.get("title") || ""}
                  value={jobFilter.title}
                  onChange={handleOnChange}
                  placeholder="Job title"
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Picky
                  options={experienceOptions}
                  labelKey="label"
                  valueKey="value"
                  // value={experience}
                  value={jobFilter.experience}
                  onChange={handleSelectExperience}
                  className="form-select-picky bg-transparent"
                  numberDisplayed={1}
                  placeholder="Select Experience"
                />
              </Col>
              <Col>
                <PlacesAutocomplete
                  // value={address}
                  value={jobFilter.address}
                  onChange={handleChangeAddress}
                  onSelect={handleSelectAddress}
                >
                  {({
                    getInputProps,
                    suggestions,
                    getSuggestionItemProps,
                    loading,
                  }) => (
                    <div className="form-control home_location_search bg-transparent d-flex align-items-center p-0 ">
                      <input
                        {...getInputProps({
                          placeholder: "Location",
                          className:
                            "w-100 h-100 border-0 form-control bg-transparent sixteen_px_text",
                        })}
                        required
                      />

                      {suggestions?.length > 0 && (
                        <div className="autocomplete-dropdown-container w-100">
                          {loading && <div>Loading...</div>}
                          {suggestions
                            .filter(
                              (suggest) =>
                                suggest.types.includes("locality") ||
                                suggest.types.includes(
                                  "administrative_area_level_1"
                                )
                            )
                            .map((suggestion, index) => {
                              const className = suggestion.active
                                ? "suggestion-item--active"
                                : "suggestion-item";
                              // inline style for demonstration purpose
                              const style = suggestion.active
                                ? {
                                    backgroundColor: "#fafafa",
                                    cursor: "pointer",
                                    color: "black",
                                    padding: "10px",
                                    borderTop: "1px solid #dbdbdb",
                                  }
                                : {
                                    backgroundColor: "#ffffff",
                                    cursor: "pointer",
                                    color: "black",
                                  };
                              return (
                                <div
                                  key={index}
                                  {...getSuggestionItemProps(suggestion, {
                                    className,
                                    style,
                                  })}
                                >
                                  <span>{suggestion.description}</span>
                                </div>
                              );
                            })}
                        </div>
                      )}
                    </div>
                  )}
                </PlacesAutocomplete>
              </Col>
            </Row>
            <button
              className="purple_btn py-3 w-100 fs-20 mt-3"
              onClick={searchJobs}
            >
              Search Jobs
            </button>
          </div>

          <div
            className={
              !user._id || user.type == "candidate"
                ? "employer_search_container p-5  disabled-box"
                : "employer_search_container p-5"
            }
          >
            <h1 className="text-left fw-bold m-0">Employer</h1>
            <Row>
              <Col>
                <Picky
                  options={industryOptions}
                  labelKey="label"
                  valueKey="value"
                  multiple={true}
                  includeFilter
                  includeSelectAll
                  value={candidateFilter.preferredIndustries}
                  onChange={handleSelectCandidatePreferredIndustry}
                  className="form-select-picky bg-transparent my-3"
                  numberDisplayed={1}
                  placeholder="Select Industry"
                  disabled={!user._id || user.type !== "employer"}
                />
              </Col>
              <Col>
                <input
                  className="form-control bg-transparent my-3 sixteen_px_text"
                  name="candidate_title"
                  // value={searchParams.get("candidate_title") || ""}
                  value={candidateFilter.title}
                  onChange={handleOnChangeCandidate}
                  placeholder="Job title"
                  disabled={!user._id || user.type !== "employer"}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Picky
                  options={experienceOptions}
                  labelKey="label"
                  valueKey="value"
                  value={candidateFilter.experience}
                  onChange={handleSelectCandidateExperience}
                  className="form-select-picky bg-transparent"
                  numberDisplayed={1}
                  placeholder="Select Experience"
                />
              </Col>
              <Col>
                <PlacesAutocomplete
                  value={candidateFilter.address}
                  onChange={handleChangeCandidateAddress}
                  onSelect={handleSelectCandidateAddress}
                >
                  {({
                    getInputProps,
                    suggestions,
                    getSuggestionItemProps,
                    loading,
                  }) => (
                    <div
                      className="form-control home_location_search bg-transparent d-flex align-items-center p-0"
                      id={suggestions.length > 0 ? "show_search" : ""}
                    >
                      <input
                        {...getInputProps({
                          placeholder: "Location",
                          className:
                            "w-100 h-100 border-0 form-control bg-transparent sixteen_px_text",
                          id: suggestions.length > 0 ? "show_search" : "",
                        })}
                        required
                        disabled={!user._id || user.type !== "employer"}
                      />

                      {suggestions && suggestions.length > 0 && (
                        <div className="autocomplete-dropdown-container w-100">
                          {loading && <div>Loading...</div>}
                          {suggestions
                            .filter(
                              (suggest) =>
                                suggest.types.includes("locality") ||
                                suggest.types.includes(
                                  "administrative_area_level_1"
                                )
                            )
                            .map((suggestion, index) => {
                              const className = suggestion.active
                                ? "suggestion-item--active"
                                : "suggestion-item";
                              // inline style for demonstration purpose
                              const style = suggestion.active
                                ? {
                                    backgroundColor: "#fafafa",
                                    cursor: "pointer",
                                    color: "black",
                                    padding: "10px",
                                    borderTop: "1px solid #dbdbdb",
                                  }
                                : {
                                    backgroundColor: "#ffffff",
                                    cursor: "pointer",
                                    color: "black",
                                  };
                              return (
                                <div
                                  key={index}
                                  {...getSuggestionItemProps(suggestion, {
                                    className,
                                    style,
                                  })}
                                >
                                  <span>{suggestion.description}</span>
                                </div>
                              );
                            })}
                        </div>
                      )}
                    </div>
                  )}
                </PlacesAutocomplete>
              </Col>
            </Row>

            <button
              className="purple_btn py-3 px-5 fs-20 mt-3 w-100"
              onClick={searchCandidates}
              disabled={!user._id || user.type !== "employer"}
            >
              Search Candidates
            </button>
          </div>
        </Col>
      </Row>
      {isNoResult && (
        <Row className="justify-content-center mt-5">
          <Col sm={10} md={6} className="d-flex flex-column align-items-center">
            <img src={sadFaceIcon} alt="sad-face" style={{ width: "10%" }} />

            <p className="eighteen_px_text mt-4">
              No search result to show. Try again by rephrasing your search
              criteria.
            </p>
          </Col>
        </Row>
      )}
      {showJobs && (
        <Row className="m-0 px-5 pb-5">
          <Col className="d-flex flex-column justify-content-center align-items-center p-5 ">
            {jobs.map((item, index) => (
              <div
                className="job_container bg-white d-flex cursor_pointer w-100 "
                key={index}
                onClick={selectJob(index)}
              >
                <div className="job_title_container d-flex flex-column p-4 w-25 sixteen_px_text ">
                  <span className="fw-bold py-2">{item.title}</span>
                  <span className="reverse_purpler_btn w-50 text-capitalize  py-2 text-center fw-bold fs-12">
                    {item.workspace.type}
                  </span>
                </div>
                <div className="job_details_container p-4 w-75">
                  {item.description.length && item.description.length > 250 ? (
                    <p className="text-muted m-0">
                      {parse(item.description.substring(0, 250))}...
                    </p>
                  ) : (
                    parse(item.description)
                  )}
                </div>
              </div>
            ))}
          </Col>
        </Row>
      )}
      {showCandidates && (
        <Row className="m-0 px-5 pb-5">
          <Col className="d-flex flex-column justify-content-center align-items-center p-5">
            {candidates.map((item, index) => (
              <div
                className="job_container bg-white d-flex cursor_pointer  w-100"
                key={index}
                onClick={selectCandidate(index)}
              >
                <div className="job_title_container d-flex flex-column p-4 w-25">
                  <span className="fw-bold py-2">{item.fullName}</span>
                  <span className="reverse_purpler_btn w-50 text-capitalize  py-2 text-center fw-bold fs-12">
                    {item.candidate.experiences[0]?.occupation}
                  </span>
                </div>
                <div className="job_details_container p-4 w-75">
                  <div className="text-muted m-0">
                    <p
                      dangerouslySetInnerHTML={{
                        __html:
                          item.candidate.additionalDetails.personalBio?.substring(
                            0,
                            230
                          ),
                      }}
                    ></p>
                  </div>
                </div>
              </div>
            ))}
          </Col>
        </Row>
      )}
    </div>
  );
}

export default Home;
