import React, { useState, useEffect } from "react";
import authServices from "../../services/AuthService";
import { useSearchParams, useNavigate } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import { calculateNewValue } from "@testing-library/user-event/dist/utils";
import Loader from "../../Loader";

const EmailVerification = ({ toggleResendEmailVerification }) => {
  const queryParams = new URLSearchParams(window.location.search);
  const navigate = useNavigate();
  let token = queryParams.get("token");
  const [isVerified, setIsVerified] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const goToHomePage = () => {
    navigate("/");
  };

  useEffect(() => {
    if (!token) {
      setIsLoading(false);
    } else {
      authServices
        .verifyEmail(`token=${token}`)
        .then((data) => {
          if (data.data.error) {
            return alert(data.data.error);
          }
          setIsVerified(true);
        })
        .catch((error) => {
          alert(error.message);
        })
        .finally(setIsLoading(false));
    }
  }, []);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div
      className="d-flex flex-column align-items-center  eighteen_px_text "
      style={{ minHeight: `calc(100vh - 320px)` }}
    >
      <div className="border-purple  px-5 py-3">
        {isVerified ? (
          <>
            {" "}
            <p className="primary_color_text text-center ">Verified!</p>
            <p className="text-center mt-2">
              You have Successfully verified the account.
            </p>
            <button
              className="purple_btn py-2 px-4 m-auto d-block"
              onClick={goToHomePage}
            >
              Go to Home page
            </button>
          </>
        ) : (
          <div className="py-4">
            <p className="text-center">
              {" "}
              Cannot verify email! Please try again
            </p>
            <button
              className="purple_btn py-2 px-4 mt-4"
              onClick={toggleResendEmailVerification}
            >
              Click here to re-send verification link
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default EmailVerification;
