import React, { useState } from "react";
import { Row, Col, Modal } from "react-bootstrap";
import { BiUpload } from "react-icons/bi";
import { BsBookmark, BsBookmarkFill , BsFillTelephoneFill, BsFillEnvelopeAtFill, BsGlobe, BsPinMapFill} from "react-icons/bs";
import { IoPersonSharp } from "react-icons/io5";
import { MdClose } from "react-icons/md";
import myResumeIcon from "../../../assets/images/myResumeIcon.png";
import videoPlay from "../../../assets/images/videoplay.svg";
import parse from "html-react-parser";

import { useSelector } from "react-redux";
import authServices from "../../../services/AuthService";
import AppliedSuccessfullyModal from "./AppliedSuccessfullyModal";
import { useEffect } from "react";

function JobDetailModal({
  toggleApplyModal,
  showApplyModal,
  showJobModal,
  toggleJobModal,
  jobs,
  jobIndex,
  setJobIndex,
  toggleLogin,
  showItems,
  handleApplyClick,
}) {
  const user = useSelector((state) => state.user);
  const selectedJob = jobs[jobIndex];
  const [documents, setDocuments] = useState({});
  const [isAppliedSuccessfully, setIsAppliedSuccessfully] = useState(false);
  const toggleAppliedSuccessfully = () => {
    setIsAppliedSuccessfully(!isAppliedSuccessfully);
  };
  const [savedStatus, setSavedStatus] = useState("");

  const prevJob = () => {
    setJobIndex((prev) => prev - 1);
  };

  const nextJob = () => {
    setJobIndex((prev) => prev + 1);
  };

  const upload = async (e) => {
    const formData = new FormData();
    formData.append("documents", e.target.files[0]);
    await authServices.uploadDocument(formData).then((res) => {
      if (res.data.error) {
        return alert(res.data.error);
      }
      setDocuments((prev) => {
        return { ...prev, [e.target.name]: res.data[0] };
      });
    });
    e.target.value = null;
  };

  const deleteDocument = (documentName) => () => {
    setDocuments((prev) => {
      let result = { ...prev };
      delete result[documentName];
      return result;
    });
  };

  const handleApply = () => {
    if (!user._id || user.type !== "candidate") {
      alert("Please log in as candidate to apply this job");
      toggleLogin();
    } else {
      toggleApplyModal();
    }
  };

  const submit = () => {
    authServices
      .applyJob(selectedJob._id, documents)
      .then((res) => {
        if (res.data.error) {
          return alert(res.data.error);
        }
        toggleApplyModal();
        toggleJobModal();
        toggleAppliedSuccessfully();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (selectedJob) {
      authServices.getSavedJobs(user._id).then((res) => {
        if (res.data.error) {
          return alert(res.data.error);
        }
        setSavedStatus(
          res.data.jobs.some((item) => item._id === selectedJob._id)
        );
      });
    }
  }, [selectedJob]);

  const saveUnsaveJob = (jobId) => {
    if (!user._id || user.type !== "candidate") {
      alert("Please log in as candidate to save this job");
      toggleLogin();
    } else {
      if (savedStatus) {
        authServices.unSaveJob(user._id, jobId).then((res) => {
          if (res.data.error) {
            return alert(res.data.error);
          }
          setSavedStatus(false);
          return alert("Unsaved the job");
        });
      } else {
        authServices.saveJob(user._id, jobId).then((res) => {
          if (res.data.error) {
            return alert(res.data.error);
          }
          setSavedStatus(true);
          return alert("Saved to My Jobs");
        });
      }
    }
  };

  if (selectedJob) {
    return (
      <>
        <Modal size="xl" show={showJobModal} onHide={toggleJobModal} centered>
          <Modal.Header closeButton />
          <Modal.Body className="custom_job_modal_body">
            <Row className="m-0 p-0 job_modal_container">
              <Col className="d-flex justify-content-center align-items-center p-5">
                <Row className="m-0 p-0">
                  <Col className="d-block  modal_left_job_details p-3" md={3}>
                    <p className="fw-bold transparent_btn fs-20 text-start ">
                      {selectedJob.recruiter &&
                      selectedJob.recruiter.companyName}
                    </p>
                    <span className="fw-bold fs-12 text-muted  mt-3">
                    
                      {selectedJob.recruiter.phone && <p><BsFillTelephoneFill></BsFillTelephoneFill><span className="ms-2">{selectedJob.recruiter.phone} </span> </p>}
                    
                      {selectedJob.recruiter.email && <p><BsFillEnvelopeAtFill></BsFillEnvelopeAtFill><span className="ms-2">{selectedJob.recruiter.email}</span></p>}                      
                     
                      {selectedJob.recruiter.companyWebsite && <p><BsGlobe></BsGlobe><span className="ms-2">{selectedJob.recruiter.companyWebsite}</span></p>}                     
                      
                      
                      {
                        selectedJob.workspace.address && 
                       <p>
                      <BsPinMapFill></BsPinMapFill>
                      <span className="ms-2">
                      {selectedJob.workspace &&
                        selectedJob.workspace.address &&
                        selectedJob.workspace.address.city &&
                        selectedJob.workspace.address.city}
                      {selectedJob.workspace &&
                      selectedJob.workspace.address &&
                      selectedJob.workspace.address.state
                        ? ", " + selectedJob.workspace.address.state
                        : ""}
                      {selectedJob.workspace &&
                      selectedJob.workspace.address &&
                      selectedJob.workspace.address.country
                        ? ", " + selectedJob.workspace.address.country
                        : ""}
                        </span>
                        </p>
                      }
                    </span>
                    <div className="d-blockn my-4">
                      {/* <span className="fw-bold">Intro Video</span>
                       <video
                        width="100%"
                        className="rounded"
                        src="https://www.w3schools.com/html/mov_bbb.mp4"
                        controls
                      />*/}
                    </div>
                    {showItems &&
                      <div className="text-center">
                        <span
                          className="fw-bold text-center cursor_pointer save-sec"
                          onClick={() => saveUnsaveJob(selectedJob._id)}
                        >
                          {savedStatus ? (
                            <span>
                              <BsBookmarkFill
                                className="me-2 highlight"
                                color="var(--purple-color)"
                              />
                              Unsave job
                            </span>
                          ) : (
                            <span>
                              <BsBookmark
                                className="me-2 highlight"
                                color="var(--purple-color)"
                              />
                              Save the job
                            </span>
                          )}
                        </span>

                        <button
                          className="purple_btn py-3 px-5 fs-20 opacity-100 mt-4 text-nowrap"
                          onClick={handleApply}
                        >
                          Apply Now
                        </button>
                      </div>
                    }
                  </Col>                  
                  <Col className="d-block"  md={9}>
                    <Row className="m-0 p-0 w-100 justify-content-between">
                       <Col className=" d-flex justify-content-start" md={6}>
                          <h1 className="fw-bold transparent_btn fs-32 text-left text-nowrap">
                            {selectedJob.title}
                          </h1>
                        </Col>
                        <Col className="d-flex justify-content-end" md={6}>
                          <div className="text-right">
                            {jobIndex !== 0 && (
                              <button
                                className="reverse_purple_border_btn py-2 px-5 fs-20 opacity-100"
                                onClick={prevJob}
                              >
                                {" "}
                                Prev{" "}
                              </button>
                            )}

                            {jobIndex < jobs.length - 1 && (
                              <button
                                className="reverse_purple_border_btn py-2 px-5 fs-20 opacity-100 ms-2"
                                onClick={nextJob}
                              >
                                {" "}
                                Next{" "}
                              </button>
                            )}
                          </div>
                      </Col>
                    </Row>
                    <Row className="m-0 p-0 mt-3">
                      <Col className="d-flex flex-column justify-content-center align-items-start">
                        <span className="fw-bold">Job Description</span>
                        <p className="text-muted mt-3">
                          {selectedJob.description &&
                            parse(selectedJob.description)}
                        </p>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>

        <Modal
          show={showApplyModal}
          onHide={toggleApplyModal}
          dialogClassName="custom_modal_size"
          centered
        >
          <Modal.Header closeButton />
          <Modal.Body className="custom_job_modal_body p-0">
            <div className="border-bottom p-4">
              <h1 className="fw-bold fs-32 text-nowrap">Apply for Job</h1>
            </div>

            <div className="p-4">
              <input
                type="file"
                id="resume"
                name="resume"
                hidden
                onChange={upload}
              />
              <label htmlFor="resume" className="w-100">
                <div className="p-4 form-control d-flex justify-content-between align-items-center cursor_pointer bg-transparent">
                  <div>
                    <img
                      src={myResumeIcon}
                      alt="resume"
                      className="me-2"
                      width="30px"
                      height="30px"
                    />
                    <span className="ms-2 transparent_btn fs-18">Resume</span>
                  </div>
                  <BiUpload size={30} color="#999999" />
                </div>
              </label>
              {documents.resume && (
                <div className="ps-4 mt-2">
                  <a href={documents.resume.url} target="_blank">
                    {documents.resume.name}
                  </a>
                  <button
                    className="bg-transparent border-0 ms-3"
                    onClick={deleteDocument("resume")}
                  >
                    <MdClose className="h-auto" color="red" />
                  </button>
                </div>
              )}

              <input
                type="file"
                id="videoResume"
                name="videoResume"
                hidden
                onChange={upload}
              />
              <label htmlFor="videoResume" className="w-100">
                <div className="p-4 form-control my-3 d-flex justify-content-between align-items-center cursor_pointer bg-transparent">
                  <div>
                    <img
                      src={videoPlay}
                      alt="video"
                      className="me-2"
                      width="30px"
                      height="30px"
                    />
                    <span className="ms-2 transparent_btn fs-18">
                      Video Resume
                    </span>
                  </div>
                  <BiUpload size={30} color="#999999" />
                </div>
              </label>
              {documents.videoResume && (
                <div className="ps-4 mt-2 ">
                  <a href={documents.videoResume.url} target="_blank">
                    {documents.videoResume.name}
                  </a>
                  <button
                    className="bg-transparent border-0 ms-3"
                    onClick={deleteDocument("videoResume")}
                  >
                    <MdClose className="h-auto" color="red" />
                  </button>
                </div>
              )}

              <input
                type="file"
                id="referenceReview"
                name="referenceReview"
                hidden
                onChange={upload}
              />
              <label htmlFor="referenceReview" className="w-100">
                <div className="p-4 form-control d-flex justify-content-between align-items-center cursor_pointer bg-transparent">
                  <div>
                    <IoPersonSharp color="var(--purple-color)" size={30} />
                    <span className="ms-2 transparent_btn fs-18">
                      References Review{" "}
                      <span className="text-muted">(Optional)</span>
                    </span>
                  </div>
                  <BiUpload size={30} color="#999999" />
                </div>
              </label>
              {documents.referenceReview && (
                <div className="ps-4 mt-2 ">
                  <a href={documents.referenceReview.url} target="_blank">
                    {documents.referenceReview.name}
                  </a>
                  <button
                    className="bg-transparent border-0 ms-3"
                    onClick={deleteDocument("referenceReview")}
                  >
                    <MdClose className="h-auto" color="red" />
                  </button>
                </div>
              )}

              <button
                className="purple_btn py-3 px-5 fs-20 opacity-100 mt-4 w-100"
                onClick={submit}
              >
                Submit
              </button>
            </div>
          </Modal.Body>
        </Modal>
        <AppliedSuccessfullyModal
          isAppliedSuccessfully={isAppliedSuccessfully}
          toggleAppliedSuccessfully={toggleAppliedSuccessfully}
          jobTitle={selectedJob.title}
          jobId={selectedJob._id}
          handleApplyClick={handleApplyClick}
        />
      </>
    );
  }
  return <></>;
}

export default JobDetailModal;
