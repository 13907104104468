// export default ["0-1", "1-2", "2-5", "5-7", "7-10", "10-15", "15-*"];
export default [
  { label: "Select Experience", value: "" },
  { label: "0 - 1 year", value: "0-1" },
  { label: "1 - 2 years", value: "1-2" },
  { label: "2 - 5 years", value: "2-5" },
  { label: "5 - 7 years", value: "5-7" },
  { label: "7 - 10 years", value: "7-10" },
  { label: "10 - 15 years", value: "10-15" },
  { label: "15 - * years", value: "15-*" },
];
